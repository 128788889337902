import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/carlight.jpg'
import pic02 from '../assets/images/kids.jpg'
import pic03 from '../assets/images/building.jpg'
import pic04 from '../assets/images/av.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Mike Weisert | UX Designer"
                    meta={[
                        { name: 'description', content: 'Mike Weisert | UX Designer' },
                        { name: 'keywords', content: 'UX Design, Development, Chicago' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>Redefining the Fleet Management Experience</h3>
                                <p>Fleet Management Organization | Rightpoint</p>
                            </header>
                            <Link to="/fleetmanagement" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>Connecting sponsors and those in need to help end child poverty</h3>
                                <p>Children International | Rightpoint</p>
                            </header>
                            <Link to="/ci" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Content marketing strategy for relationship-based businesses</h3>
                                <p>One North Interactive</p>
                            </header>
                            <Link to="/professional-services" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Application ecosystem for managing $200m+ annually in audiovisual</h3>
                                <p>Whitlock</p>
                            </header>
                            <Link to="/audio-visual" className="link primary"></Link>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <div className="grid-wrapper">
                                <div className="col-3">
                                    <h3><span className="icon fa-list-alt"></span> UX Process</h3>
                                    <ul>
                                        <li>User Research</li>
                                        <li>Testing Methodologies</li>
                                        <li>Design Sprints</li>
                                        <li>Prototyping</li>
                                        <li>Agile Development</li>
                                        <li>Functional Documentation</li>
                                    </ul>
                                </div>
                                <div className="col-3">
                                    <h3><span className="icon fa-wrench"></span> Design Tools</h3>
                                    <ul>
                                        <li>Axure</li>
                                        <li>Sketch</li>
                                        <li>Framer X</li>
                                        <li>InVision</li>
                                        <li>Adobe XD</li>
                                    </ul>
                                </div>
                                <div className="col-3">
                                    <h3><span className="icon fa-code"></span> Code</h3>
                                    <ul>
                                        <li>React</li>
                                        <li>Working in Angular-based teams</li>
                                        <li>CSS</li>
                                        <li>Javascript</li>
                                        <li>SQL</li>
                                        <li>ColdFusion</li>
                                    </ul>
                                </div>
                                <div className="col-3">
                                    <h3><span className="icon fa-area-chart"></span> Systems &amp; Analysis</h3>
                                    <ul>
                                        <li>Sitecore Marketing Certified</li>
                                        <li>Google Tag Manager / Analytics</li>
                                        <li>Web tracking analytics</li>
                                        <li>Episerver</li>
                                        <li>Sharepoint</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex