import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
        <div className="content">
            
            <h1><span className="line">I lead design &amp; development teams to craft impactful, </span><span className="noWrap">user-centric experiences</span></h1>
                    
        </div>
        </div>
    </section>
)

export default Banner
